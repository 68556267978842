// extracted by mini-css-extract-plugin
export var accentColor = "contact-module--accentColor--85a2f";
export var blackText = "contact-module--blackText--e4a05";
export var bottomGap = "contact-module--bottomGap--6d46a";
export var btn = "contact-module--btn--4510e";
export var col6 = "contact-module--col6--81f93";
export var contactBtn = "contact-module--contactBtn--d1ddc";
export var container = "contact-module--container--5aef4";
export var errorAlert = "contact-module--errorAlert--a5d4e";
export var errorMessage = "contact-module--errorMessage--154a1";
export var facebook = "contact-module--facebook--e6944";
export var formAlert = "contact-module--formAlert--df5a2";
export var formGroup = "contact-module--formGroup--9f853";
export var formInput = "contact-module--formInput--b065a";
export var gridRow = "contact-module--gridRow--4d093";
export var headerDesc = "contact-module--headerDesc--bc8af";
export var inputError = "contact-module--inputError--e04a8";
export var instagram = "contact-module--instagram--80ca2";
export var linkedin = "contact-module--linkedin--626e5";
export var screenReaderText = "contact-module--screenReaderText--8542e";
export var section = "contact-module--section--9153c";
export var sectionHeader = "contact-module--sectionHeader--9aa5b";
export var smallHeader = "contact-module--smallHeader--6c8bb";
export var socialIcons = "contact-module--socialIcons--8f8a5";
export var successAlert = "contact-module--successAlert--6dbb3";
export var textarea = "contact-module--textarea--f4b56";
export var twitter = "contact-module--twitter--36cca";
export var youtube = "contact-module--youtube--b23fd";